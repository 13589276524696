export enum DEFECT_TYPES {
  DRUHA_JAKOST = '2-jakost',
  ROZBALENE = 'rozbalene',
}

export interface DruhaJakostDTO {
  _prcena: number
  _prcenaSk: number
  _prcenasdph: number
  _prmena: Prmena
  _sernum: number
  _sklad: Sklad
  _spdan: number
  celsaz_id: string
  delka: number
  doba_dodani: string
  dph: number
  eet_evid: number
  hled1: Hled1
  hmotnost: number
  id_material: string
  kvydeji: number
  lih: number
  mj_id: MjID
  mjdopln: string
  mjvydej_id: MjID
  nasklade: number
  nazev: string
  nazevSk: string
  nefakturovat: number
  obal1: MjID
  obal1ks: number
  obal2ks: number
  obal3ks: number
  obalmnoz: number
  objem: number
  objem_mat: number
  objem_skladem: number
  prodej_povolen: number
  rich_popis_pro_web?: string
  poznweb?: string
  poznwebSk?: string
  kat_id?: string
  recyklskup?: string
  recykltyp: number
  rezerv: number
  skupina_id: SkupinaID
  typmat: number
  typrozmer: number
  web: number
  zalozeno: Date
  obrazky?: string | string[]
  djpopis?: string
  djpopisSk?: string
  djzaruka?: string
  rzpopis?: string
  rzpopisSk?: string
  kat1?: string
  parent_id?: string
  parent_prcena?: number
  parent_nazev?: string
  parent_prcena_sk?: number
  djprovaz?: string
  rzprovaz?: string
}

export enum Prmena {
  Czk = 'CZK',
}

export enum Sklad {
  Z1 = 'Z1',
}

export type Hled1 = {
  Asistent: 'asistent'
  [x: string]: string
}

export type MjID = {
  Ks: 'ks'
  [x: string]: string
}

export type SkupinaID = {
  Ctv1: 'CTV1'
  Ctv10: 'CTV10'
  Ctv19: 'CTV19'
  Ctv22: 'CTV22'
  [x: string]: string
}
