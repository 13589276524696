export enum CASES {
  COMPLAINT = 'COMPLAINT',
  SERVICE = 'SERVICE',
  INSTALLATION = 'INSTALLATION',
  WITHDRAWAL = 'WITHDRAWAL',
}

export type ICaseState = {
  [x in keyof typeof CASES]: IServiceState[]
}

export type ICase = {
  label: string
  value: keyof typeof CASES
}

export const OPERATOR_GROUP = 203
export const ADMIN_GROUP = 196

export interface IServiceState {
  value: number
  label: string
  description?: string
  color?: string
}

export const SERVICE_CASES = {
  [CASES.COMPLAINT]: 'Reklamace',
  [CASES.SERVICE]: 'Servis',
  [CASES.INSTALLATION]: 'Instalace',
  [CASES.WITHDRAWAL]: 'Odstoupeni',
}

export const SERVICE_CASES_OPTIONS: ICase[] = [
  { label: SERVICE_CASES[CASES.COMPLAINT], value: CASES.COMPLAINT },
  { label: SERVICE_CASES[CASES.SERVICE], value: CASES.SERVICE },
  { label: SERVICE_CASES[CASES.INSTALLATION], value: CASES.INSTALLATION },
  { label: SERVICE_CASES[CASES.WITHDRAWAL], value: CASES.WITHDRAWAL },
]

export const SERVICE_STATES: ICaseState = {
  [CASES.WITHDRAWAL]: [
    {
      value: 0,
      label: 'Přijato',
      color: '#fffff',
    },
    {
      value: 1,
      label: 'uznáno',
    },
    {
      value: 2,
      label: 'neuznáno',
    },
    {
      value: 3,
      label: 'posouzení',
    },
    {
      value: 4,
      label: 'vyřízeno',
    },
  ],
  [CASES.COMPLAINT]: [
    {
      value: 0,
      label: 'Přijato',
      color: '#fffff',
    },
    {
      value: 1,
      label: 'U dodavatele',
    },
    {
      value: 2,
      label: 'U technika',
    },
    {
      value: 3,
      label: 'uznáno',
    },
    {
      value: 4,
      label: 'neuznáno',
    },
    {
      value: 5,
      label: 'posouzení',
    },
    {
      value: 6,
      label: 'vyřízeno',
    },
  ],
  [CASES.SERVICE]: [
    {
      value: 0,
      label: 'Přijato',
    },
    {
      value: 1,
      label: 'řešení',
    },
    {
      value: 2,
      label: 'Naplánovat',
    },
    {
      value: 3,
      label: 'Příprava',
    },
    {
      value: 4,
      label: 'Vyřízeno',
    },
  ],
  [CASES.INSTALLATION]: [
    {
      value: 0,
      label: 'Přijato',
    },
    {
      value: 1,
      label: 'Řešeno Z',
    },
    {
      value: 2,
      label: 'Řešeno O',
    },
    {
      value: 3,
      label: 'Naplánovat',
    },
    {
      value: 4,
      label: 'Příprava',
    },
    {
      value: 5,
      label: 'Vyřízeno',
    },
  ],
}
export type CaseState = 0 | 1 | 2

export const SERVICE_INSTRUCTION_NAME = {
  NavodCZ: 'Manuál',
  TechPopis: 'Technický list',
} as const
