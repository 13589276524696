import { BusinessPartnerDTO } from './business-partner.dto'
import { OrderItem } from './orders.dto'
import { Product } from './product.dto'

export * from './stock-address.dto'
export * from './business-partner.dto'
export * from './product.dto'
export * from './returned-good.dto'
export * from './services.dto'
export * from './defects.dto'
export * from './sales.dto'
export * from './inventory.dto'
export * from './sale-automate.dto'
export * from './sets.dto'
export * from './attachment.dto'
export * from './muurikka.dto'
export * from './firm.dto'
export * from './ticket.dto'
export * from './auth-user.dto'
export * from './orders.dto'
export * from './export-sales.dto'
export * from './contracts.dto'
export * from './stock-item.dto'

export interface SupplierDTO {
  id_ico?: string
  dic?: string
  nazev1?: string
  ulice?: string
  obec?: string
  psc?: string
  stat_id?: string
  email?: string
  http?: string
  mena_id?: string
  tel?: string
  jazyk_id?: string
  pozn?: string
}
export class BusinessContactDTO extends BusinessPartnerDTO {
  _firma?: string
}
export interface StockDTO {
  id_sklad?: string
  nazev?: string
  pouz?: number
  konsig?: number
  _wms?: number
  _umist?: number
  vychozipevcen?: number
  cisloceny?: number
  typ?: number
  reklam?: number
  _inventura?: number
  ocen?: number
}

export class VisionCreateAddressItemDTO {
  dataid!: number
  pporadi!: number
  pdataid!: number
  mnoz!: number
  mat!: string
}

export enum CURRENCY {
  CZK = 'CZK',
  EUR = 'EUR',
}

export enum LOCALES {
  CZ = 'cs',
  SK = 'sk',
}

export interface WholesaleAccount {
  _id?: string
  name?: string
  firm?: string
  ico?: string
  location?: 'cz' | 'sk' | 'cs'
  locale: LOCALES
  currency: CURRENCY
  dic?: string
  cart?: string
  lastActivity?: string
  email?: string
  phone?: string
  password?: string
  updatedAt?: Date
  mena_id?: CurrencyType
  obrat?: number
  obec?: string
  ulice?: string
  psc?: string
  dph?: boolean
  active?: boolean
  admin?: boolean
  isConfirmed?: boolean
  confirmToken?: string
  confirmTokenExpire?: Date
  survey?: Record<string, unknown>
  shipment?: Record<string, unknown>

  forceLogin?: boolean
}

export class DeliveryShipping {
  name?: string
  email?: string
  phone?: string
  ulice?: string
  note?: string
  obec?: string
  psc?: string
  state?: 'cz' | 'sk'
  location?: 'cz' | 'sk'
}

export interface CartProduct {
  id_material: string
  count: number
  price: number
  product: Partial<Product>
  [x: string]: unknown
}

export interface WholesaleAccountCart {
  _id?: string
  user: string | WholesaleAccount
  total: number
  count: number
  products: CartProduct[]
  shipping?: DeliveryShipping
}

export interface WholesaleAccountHistory {
  user?: string | WholesaleAccount
  products: string[]
}

export type CurrencyType = 'CZK' | 'EUR'

export interface TopTransResponse<D = unknown> {
  status: string
  data: D
  errors: string[]
}

export class ShipmentDTO {
  orderNumber!: number
  loadingDate?: string
  itemFrom?: number
  itemTo?: number
  label?: string
  deliveryBranch?: string
  deliveryDriver?: string
  deliveryDate?: string
  status?: string
}

export interface ShipmentSelection {
  idDokl: string
  stock?: string
  obd?: string
  json: Record<string, unknown>
  id?: string
  metric?: string
  description?: string
}

export interface SearchProductDTO {
  id_material: string
  nazev: string
  obrazky?: string[]
  poznweb?: string
  mj_id?: string
  mjvydej_id?: string
  prcena?: string
  price?: string
  stavnaskladez1?: number
  stavnaskladez101?: number
  ean?: string
  sk_cena_finskasauna?: number
  price_sk?: string
  koef_zvj_1?: number
}

export interface ExtendedSearchProductDTO extends SearchProductDTO {
  rich_popis_pro_web?: string
  sk_popis?: string
  sk_nazev?: string
  sk_cena_finskasauna?: number
}

export interface IDruhaJakostLastItem {
  id_material: string
  number: number
  skupina_id: string
  mj_id: string
  dph: number
  mena_id: string
  nakupcena?: number
  nakupmena?: string
}

export interface IDruhaJakostProduct {
  hled1: string
  navod: string
  prcena: number
  dph: number
  povProd: number
  nakupmena: string
  pozn: string
  rich_popis_pro_web: string
  kategorie: string
  prodCenaSk: number
  zakMena: string
  sk_nazev: string
  aj_nazev?: string
  mj_id: string
  tech_popis: string
  hmotnost: number
  poznweb: string
  delka: number
  vyska: number
  sk_rich_popis_pro_web: string
  mjdopln: string
  cizikod: string
  ico_id: string
  logo: string
  navod_sk: string
  zakCena: number
  doporucene_doplnky: string
  nazev: string
  obrazky: string
  mikro_kategorie: string
  mjvydej_id: string
  vyrZak: number
  tech_popis_sk: string
  zobrWeb: number
  sirka: number
  skupina_id: string
  zarukaPrv: number
  obalmnoz: number
  inStat: string
  nakupcena: number
  id_material: string
  parametry: string
  sk_parametry: string
  recPoplatek?: string
  idMatDod?: string
}

export class CommentDTO {
  comment!: string
}

export interface IMjId {
  id_mj?: string
  popis?: string
}

export class IAuthUser {
  user_id!: number
  dbaauth!: 1 | 0
  user_name!: string
  flags!: number
  email!: string
  tel?: string
  fullname!: string
  object_id!: number
  groups?: IUserGroup[]
}

export class IUserGroup {
  id!: number
  name!: string
}

export interface ImportData {
  id_material: string
  name: string
  count: number
  stock: string
  stockId: number
  stockAddress: string
}

export interface PriceGroup {
  id: string
  name: string
  margin: string
}


export interface Message {
  message: string
}

export type CountryCode = 'cz' | 'sk'

export interface IResponse<T = unknown> {
  total?: number
  items?: T
}

export type PickUpExportType = 'tt' | 'dpd' | 'ppl' | 'gw' | 'customers'

export interface Order {
  stav: number
  jazyk_id: JazykID
  prijemico_id: string
  prijemmulti_id: number
  cl4?: string
  pozn_main: string
  id_dokl: number
  mena_id: MenaID
  polozek: number
  total: number
  rada_id: string
  ico_id: string
  multi_id: number
  termin?: string
  terminzac?: string
  faktmulti_id: number
  zalozeno: string
  doprava?: Doprava
  uhrada?: Doprava
  _fakturovat?: string
  _dorucit?: string
  orderItems?: OrderItem[]
}

export interface Doprava {
  id: string
  text: string
}

export enum JazykID {
  Csy = 'CSY',
}

export enum MenaID {
  Czk = 'CZK',
}

export interface IColor {
  r: number
  g: number
  b: number
  a?: number
  roundA?: number
  hex: string
  rgba: string
}

export type ICallback<T = unknown> = (err?: Error | null, data?: T) => void
export interface IListResponse<T = unknown[]> {
  items: T
  total: number
}
