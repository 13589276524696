import { AuthUser } from "./auth-user.dto"

export const COUNTRIES = {
  CZ: 'CZ',
  SK: 'SK',
} as const

export type Country = keyof typeof COUNTRIES

export class ServiceClientDTO {
  ico?: string
  multi_id?: number
  name?: string
  /** @deprecated */
  address?: string
  street?: string
  city?: string
  zip?: string
  phone?: string
  email?: string
  country?: Country
}

export enum SERVICE_PART_TYPE {
  MATERIAL = 'material',
  SERVICE = 'service',
}

export const SERVICE_PART_TYPE_TITLES = {
  [SERVICE_PART_TYPE.MATERIAL]: 'Material',
  [SERVICE_PART_TYPE.SERVICE]: 'Služba',
} as const

export interface OfferItemDTO {
  mat: string
  nazev?: string
  mnoz: number
  cena: number
  dph?: number
  mjvydej?: string
  type: SERVICE_PART_TYPE
}

export enum SERVICE_CASES_ENUM {
  COMPLAINT = 'COMPLAINT',
  SERVICE = 'SERVICE',
  INSTALLATION = 'INSTALLATION',
  WITHDRAWAL = 'WITHDRAWAL',
}

export interface ServiceUser {
  user_id: string
  user_name: string
  fullname?: string
  email?: string
}

export interface ServiceItem {
  nazev: string
  id_material: string
  kat_id: string
}

export class ServicePart {
  id?: string
  name?: string
  count?: number
  price?: number
  price_sk?: number
  dph?: number
  mj_id?: string
  type?: SERVICE_PART_TYPE
}

export class DiffType {
  date!: Date;
  user!: string;
  value!: DiffValue;
}

export class DiffMap {
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newData?: any;
}

export type DiffValue = { [x: string]: DiffMap | Record<string, unknown> | { [x: string]: unknown } };

export class ServiceComment {
  _id!: string
  user!: AuthUser
  date!: number | Date
  text!: string
}

export interface ServiceAttachmentDTO {
  id: string
  name: string
  type?: string
  size?: number
  lastModified?: number
  _static?: boolean
}

interface OfferItem {
  id?: string
  rada?: string | number
}

type Offer = OfferItem & {
  total?: number
  totalDPH?: number
}

export class ServiceOffer {
  offer?: Offer
  contract?: OfferItem
  order?: OfferItem
  customer?: OfferItem
}

export class ServiceDTO {
  _id?: string
  id?: string
  case?: SERVICE_CASES_ENUM
  state?: number
  operator?: ServiceUser | string
  dueDate?: Date | number
  defectNote?: string
  client?: ServiceClientDTO
  internalState?: number | null
  createdBy?: AuthUser
  updatedBy?: AuthUser
  serviceItem?: ServiceItem | string
  stoveRegulation?: ServiceItem | string
  createdAt?: number | Date
  updatedAt?: number | Date
  serviceParts?: ServicePart[]
  history?: DiffType[]
  comments?: ServiceComment[]
  _comments?: ServiceComment[]
  commentsCount?: number = 0
  autoMail?: boolean = true
  attachments?: ServiceAttachmentDTO[] = []
  attachments_count?: number = 0
  hasOffer?: boolean = false
  offer?: ServiceOffer
  commentsSeenBy?: number[]
  relatedReturn?: string
}
